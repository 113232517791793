import React from "react";
import mergeObject from "@gqlapp/base/utils/merge/mergeObject";
import redirectTo from '@vinhxuan/common/redirect';
import { getRoute, ROUTER } from "@vinhxuan/router-common";
import clientModules from '@vinhxuan/client';
import User from "@vinhxuan/user-common/classes/User";
import { getItem } from "@gqlapp/core-common/clientStorage";

/**
 * Default Page
 * @param props
 * @constructor
 */
export default function Page(props: any){
  // @ts-ignore
  return React.createElement(clientModules.router, props)
}

export const getInitialProps = async ({ load, route_type, apolloClient, asPath, path, headers, query, ...ctx }: any = {})=>{
  let type_name, graphqlRoute: any = {}, session: any = {}, currentUser: any, redirect, site, firebaseSettings;
  ctx = mergeObject({ headers, apolloClient, path, asPath, query }, ctx );

  try {

    // debug('######withRouteContainer',{ path, apolloClient })
    /** Load du lieu website cung voi cookie */
    const result = await getRoute({ variables: { path }})(apolloClient);

    // debug('######withRouteContainer',{ result, route_type })
    let data: any = result?.data || {};

    await apolloClient.writeQuery({ query: ROUTER, data, variables: { path }});

    /** Set variable */
    currentUser = data?.currentUser || {};
    site = data?.site || {};
    session = data?.session || {};

    firebaseSettings = data?.site?.firebase || {}
    /*** The variable has been set */


    /** * Checked load database */
    if(load){
      graphqlRoute = data?.res || {};
      type_name = graphqlRoute?.type_name;
      if(type_name === 'TypeIsRedirect' && graphqlRoute?.title){
        redirect = graphqlRoute?.title;
      }
    }
  }catch (e) {
    debug(path, e)
  }

  route_type = route_type || type_name;

  /** * redirect from getInitialProps */
  if(type_name === 'TypeIsRedirect' && redirect && redirect !== path){
    redirectTo(redirect, ctx);
  }

  /** get route_type **/
  clientModules.props = { route_type, path };
  (clientModules.typeByRoute) && (route_type = clientModules.typeByRoute);

  // debug('######withRoute', 'getInitialProps', { route_type, type_name, query })


  try{
    const preurl = await getItem('location')
    ctx = mergeObject({ preurl }, ctx)
  }catch (e) { debug(e); }

  ctx = mergeObject({ session, site, firebaseSettings, currentUser, headers, graphqlRoute, route_type }, ctx)
  try{
    let routeProps: any = clientModules.getRouteProps;

    if(routeProps){
      const { runtimejs, perms, redirectOnLoggedIn, redirectOnAnonymous, redirect: to = '/' } = routeProps || {};

      ctx = mergeObject({ runtimejs }, ctx);

      /**
       * check type of page
       */
      try{
        let user = User.instanse(currentUser);

        if(to !== path && (
            (typeof redirectOnLoggedIn == 'boolean' && redirectOnLoggedIn && !!user.id) ||
            (typeof redirectOnAnonymous == 'boolean' && redirectOnAnonymous && !user.id) ||
            ((perms && !user.hasPerms(perms)))
        )){
          redirectTo(to, ctx);
        }
      }catch (e) {
        debug(e)
      }

      (routeProps.getInitialProps) && (ctx = mergeObject(await routeProps.getInitialProps(ctx), ctx))
    }

  }catch (e) {
    debug(e)
  }


  return ctx;
}
