import { graphql } from 'react-apollo';
// @ts-ignore
import { ROUTER } from '../graphql';
import { compose, withProps } from 'recompose';
import mapInput from '@gqlapp/base/utils/mapInput';

/**
 * @var client graphql
 * @param fetchPolicy
 * @param variables
 */
export const getRoute = ({ fetchPolicy, variables }: any = {}) => (client: any) => client.query({
  query: ROUTER,
  fetchPolicy: fetchPolicy || 'cache-first',
  variables: variables || {}
})

/**
 *
 * @param Component
 */
export const withRouteQuery = (input: any) => (Component: any) =>
  compose(
    withProps((props: any) => mapInput(input, props)),
    // withProps(({ asPath, routeSkip }: any) => error({ asPath, routeSkip })),
    graphql(ROUTER, {
      skip: ({ routeSkip, asPath }: any) => !asPath || routeSkip,
      options: ({ asPath: path, load }: any) => {
      path = path || '/';
      path = `${path}`.replace('.html', '');
      return ({ variables: { path, load: !!load }})
    },
    props: async ({ data: { loading, route: content }, ownProps: { route } }: any) => {
      if (!loading) { route.save(content || {}); }
      return { loading, route};
    }
  })
  )(Component);
